import {
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  Heading,
  HStack,
  IconButton,
  Spinner,
  Stack,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
  useBoolean,
  useToast,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { Business } from "qs-api";
import { useAuthentication } from "qsui";
import { Fragment, useEffect, useState } from "react";
import { AiFillSetting } from "react-icons/ai";
import { FaBuilding } from "react-icons/fa";
import { FiPlus } from "react-icons/fi";
import { IoMdPeople } from "react-icons/io";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function BusinessSelection() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useBoolean(true);
  const moduleLabelbyURL = {
    [process.env.REACT_APP_MAINTENANCE_URL!]: "Maintenance",
    [process.env.REACT_APP_AUDITOR_URL!]: "Auditor",
    [process.env.REACT_APP_TIMESHEET_URL!]: "Timesheet",
  };
  const { user } = useAuthentication();
  const toast = useToast({
    position: "top",
    isClosable: true,
  });
  const [searchParams] = useSearchParams();
  const goto = searchParams.get("goto");
  const [businesses, setBusinesses] = useState<Array<Business.BusinessMin>>([]);
  useEffect(() => {
    Business.FetchBusinesses(
      (businesses) => {
        if (businesses.length > 0 && !user?.defaultBusiness) {
          setDefaultBusiness(businesses?.[0]?.id);
        }
        setBusinesses(businesses);
        setIsLoading.off();
      },
      (error) => {
        toast({
          status: "error",
          title: "Error",
          description: error,
        });
      }
    );
  }, []);

  function setDefaultBusiness(id: string, goto?: string) {
    Business.SetDefaultBusiness(
      id,
      (response) => {
        localStorage.setItem(
          "user",
          JSON.stringify({
            ...user,
            ...response,
          })
        );
        if (goto) {
          setTimeout(() => {
            window.location.href = `${goto}/?token=${response?.token}`;
          }, 1000);
        }
      },
      (error) => {
        toast({
          status: "error",
          title: "Error",
          description: error,
        });
      }
    );
  }

  return (
    <Fragment>
      <Container maxW="container.lg" my={10} mt="85px">
        <HStack>
          <Heading flex={1}>
            Welcome{" "}
            <Text color="primary.500" as="span">
              {user?.title}
            </Text>{" "}
            !
          </Heading>
          <Button
            leftIcon={<FiPlus />}
            iconSpacing={0}
            justifyContent="center"
            onClick={() => {
              navigate(`/app/addbusiness`);
            }}
            variant="solid"
            borderRadius="5px"
            border="none"
          >
            Add Business
          </Button>
        </HStack>
        <Text>Select one of your businesses to continue</Text>
        <VStack mt={10}>
          {isLoading ? (
            <Stack w="100%" h="30vh" justify="center" align="center">
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="primary.500"
                size="xl"
              />
            </Stack>
          ) : (
            businesses.map((business, i) => (
              <Box
                key={i}
                w="100%"
                shadow="base"
                _hover={{
                  shadow: "lg",
                }}
                borderRadius={10}
                p={5}
              >
                <Stack
                  direction={{
                    xs: "column",
                    sm: "row",
                  }}
                  justify="space-between"
                  align="center"
                >
                  <Stack
                    direction={{
                      xs: "column",
                      sm: "row",
                    }}
                    align="center"
                  >
                    <Avatar
                      bg="primary.300"
                      size={{
                        xs: "lg",
                        md: "md",
                      }}
                      name={business.businessName}
                      src={business.logoPath}
                    />
                    <Text fontWeight="bold" fontSize="2xl">
                      {business.businessName}
                    </Text>
                  </Stack>
                  <IconButton
                    aria-label="edit"
                    variant="link"
                    icon={<AiFillSetting size={25} />}
                    onClick={() => {
                      navigate(`/app/business/${business.id}`);
                    }}
                  />
                </Stack>
                <Stack
                  align="center"
                  justify={{
                    md: "flex-start",
                    xs: "center",
                  }}
                  // direction={{
                  //   xs: "column",
                  //   md: "row",
                  // }}
                  direction="row"
                  mt={2}
                >
                  <Tag variant="outline" colorScheme="secondary">
                    <TagLeftIcon as={IoMdPeople} />
                    <TagLabel>{business.numberOfPeople} Employees</TagLabel>
                  </Tag>
                  <Tag variant="outline" colorScheme="accent">
                    <TagLeftIcon as={FaBuilding} />
                    <TagLabel>{business.numberOfLocations} Locations</TagLabel>
                  </Tag>
                </Stack>
                <Divider my={2} />
                <Text fontWeight="bold">Continue to</Text>
                <Wrap
                  justify={{
                    xs: "center",
                    md: "flex-start",
                  }}
                >
                  <WrapItem>
                    <Button
                      variant="ghost"
                      onClick={() => {
                        setDefaultBusiness(
                          business.id,
                          process.env.REACT_APP_TIMESHEET_URL!
                        );
                      }}
                    >
                      Timesheet
                    </Button>
                  </WrapItem>
                  <WrapItem>
                    <Button
                      variant="ghost"
                      onClick={() => {
                        setDefaultBusiness(
                          business.id,
                          process.env.REACT_APP_AUDITOR_URL!
                        );
                      }}
                    >
                      Audit
                    </Button>
                  </WrapItem>
                  <WrapItem>
                    <Button
                      variant="ghost"
                      onClick={() => {
                        setDefaultBusiness(
                          business.id,
                          process.env.REACT_APP_MAINTENANCE_URL!
                        );
                      }}
                    >
                      Maintenance
                    </Button>
                  </WrapItem>
                </Wrap>
                {/* <Divider my={2} />
                <HStack
                  justify={{
                    xs: "center",
                    md: "flex-end",
                  }}
                >
                  <Button
                    variant="solid"
                    rightIcon={<FiArrowRight size={25} />}
                    onClick={() => {
                      goto && setDefaultBusiness(business.id, goto);
                    }}
                  >
                    Continue {goto && `to ${moduleLabelbyURL[goto]}`}
                  </Button>
                  {/* <Text color="secondary.500">Continue</Text>
                        <IconButton
                          colorScheme="secondary"
                          variant={"ghost"}
                          aria-label="continue-business"
                          icon={<FiArrowRight size={25} />}
                        /> */}
                {/* </HStack>  */}
              </Box>
            ))
          )}
        </VStack>
      </Container>
    </Fragment>
  );
}
